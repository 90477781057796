import React from 'react'
import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import Button from 'components/CustomButtons/Button.js'
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image';
import SectionPriceExample from 'pages-sections/SectionPriceExample';
import LandingServices from 'pages-sections/landing/LandingServices'
import { RightSection, LeftSection } from 'components/SectionsTextPic/Sections';
import Electricidad from "assets/img/sections/electricista.jpg";

const useStyles = makeStyles(style)

const Domestico = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="⚡ Suburled - Electricistas en Barcelona Provincia"
      pageDescription="✨ Reformamos la instalación eléctrica de tu vivienda o comunidad de vecinos, llave en mano. Nos encargamos de los trámites y legalización. Electricistas con gran experiencia en Barcelona Provincia. Contamos con albañiles y fontaneros en el equipo para completar la instalación de tu casa."
      // headerImageGatsby={data.electricidadJson.headerImage.childImageSharp.fluid}
      headerImage={Electricidad}
      bgImgStyle={{backgroundPositionY: "top"}}
      filter="dark"
      content={
        <React.Fragment>
          <h1 className={classes.title}>Electricistas en Barcelona</h1>
          <h2 className={classes.subtitle}>Instalaciones eléctricas domésticas, comerciales y en comunidades de vecinos</h2>

          <Link to="/presupuesto" title="Solicita Presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.mrBottom
          )}
        >
        <div dangerouslySetInnerHTML={{__html: data.electricidadJson.content.childMarkdownRemark.html}} />
      </GridItem>
      

      {data.electricidadJson.gallery.map((obj, i) => {
        if (i % 2 == 0) {
          return (
            <LeftSection
              key={i}
              classes={classes} 
              image={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              text={obj.text}
              title={obj.title}
            />
          )
        } else return (
          <RightSection 
            key={i}
            classes={classes} 
            image={obj.image.childImageSharp.fluid}
            alt={obj.alt}
            text={obj.text}
            title={obj.title}
          />
        )
      })}

      <LandingServices classes={classes} />
    </Layout>
  )  
}

export default Domestico

export const query = graphql`
  query ReformasElectricidadpageQuery {
    electricidadJson {
      headerImage {
        childImageSharp {
          fluid(maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        text
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;